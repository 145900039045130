/**
 * 获取当前时间
 * @descripttion: 时间戳转换
 * @Author:
 * @Date: 2021-06-23 22:07:16
 * @输出格式:2022-12-04 17:15:53
 * @调用方法 timestampToTime(时间戳)
 */
export function timestampToTime(timestamp) {
  var date = new Date(timestamp * 1000); //这个是10位数字的转换 如果想要13位数的话需要乘1000
  var Y = date.getFullYear() + "-";
  var M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "";
  return Y + M + D;
}

export function uploadDate(timestamp) {
  var date = new Date(timestamp); //这个是10位数字的转换 如果想要13位数的话需要乘1000
  var Y = date.getFullYear();
  var M =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  return Y + M + D;
}
// ***年
export function uploadYear(timestamp) {
  var date = new Date(timestamp * 1000);
  var Y = date.getFullYear();
  return Y + "年";
}

// 获取月日
export function getMinuteHour(timestamp) {
  var date = new Date(timestamp * 1000); //这个是10位数字的转换 如果想要13位数的话需要乘1000
  var M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "";
  return M + D;
}

// 获取月日时分
export function getMonthDayTime(timestamp) {
  var date = new Date(timestamp * 1000); //这个是10位数字的转换 如果想要13位数的话需要乘1000
  var M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "";
  var h =
    date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
  var m =
    date.getMinutes() < 10 ? "0" + date.getMinutes() + "" : date.getMinutes();
  return M + D + " " + h + m;
}
//  时:分格式
export function getTimeUnit(timestamp) {
  var date = new Date(timestamp * 1000); //这个是10位数字的转换 如果想要13位数的话需要乘1000

  var h =
    date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
  var m =
    date.getMinutes() < 10 ? "0" + date.getMinutes() + "" : date.getMinutes();
  return h + m;
}
// M月D日 时:分格式
export function getMonthDayTimeUnit(timestamp) {
  var date = new Date(timestamp * 1000); //这个是10位数字的转换 如果想要13位数的话需要乘1000
  var M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "月";
  var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "日 ";
  var h =
    date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
  var m =
    date.getMinutes() < 10 ? "0" + date.getMinutes() + "" : date.getMinutes();
  return M + D + " " + h + m;
}
// M月D日 星期
export function getWeekday(timestamp) {
  var date = new Date(timestamp * 1000); //这个是10位数字的转换 如果想要13位数的话需要乘1000
  var M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "月";
  var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "日 ";
  var h =
    date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
  var m =
    date.getMinutes() < 10 ? "0" + date.getMinutes() + "" : date.getMinutes();
  const options = { weekday: "long" };
  const week = new Intl.DateTimeFormat("en-US", options).format(date);
  // 将英文的星期几转换为中文的形式
  const chineseWeek = {
    Monday: "星期一",
    Tuesday: "星期二",
    Wednesday: "星期三",
    Thursday: "星期四",
    Friday: "星期五",
    Saturday: "星期六",
    Sunday: "星期日",
  };
  return M + D + " " + chineseWeek[week];
}
// 获取年月日时分
export function getMonthHourTime(timestamp) {
  var date = new Date(timestamp * 1000); //这个是10位数字的转换 如果想要13位数的话需要乘1000
  var Y = date.getFullYear() + "-";
  var M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "";
  var h =
    date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
  var m =
    date.getMinutes() < 10 ? "0" + date.getMinutes() + "" : date.getMinutes();
  return Y + M + D + " " + h + m;
}
// 获取年月日时分秒
export function getTime(timestamp) {
  var date = new Date(timestamp * 1000); //这个是10位数字的转换 如果想要13位数的话需要乘1000
  var Y = date.getFullYear() + "-";
  var M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "";
  var h =
    date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
  var m =
    date.getMinutes() < 10
      ? "0" + date.getMinutes() + ":"
      : date.getMinutes() + ":";
  var s =
    date.getSeconds() < 10 ? "0" + date.getSeconds() + "" : date.getSeconds();
  return Y + M + D + " " + h + m + s;
}
// 获取年/月/日时分秒
export function getNewTime(timestamp) {
  var date = new Date(timestamp * 1000); //这个是10位数字的转换 如果想要13位数的话需要乘1000
  var Y = date.getFullYear() + "/";
  var M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "/";
  var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "";
  var h =
    date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
  var m =
    date.getMinutes() < 10
      ? "0" + date.getMinutes() + ":"
      : date.getMinutes() + ":";
  var s =
    date.getSeconds() < 10 ? "0" + date.getSeconds() + "" : date.getSeconds();
  return Y + M + D + " " + h + m + s;
}
// 获取完整的时间
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (("" + time).length === 10) time = parseInt(time) * 1000;
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
  return time_str;
}

/**
 * 获取时间戳
 * @param {number} time - 时间值
 * @param {number} step - 取值1（当前time为毫秒值）或1000（当前time为秒值）
 * @return {string} 时间戳
 * @example
 * // returns 8:54:21
 */
export function dataTurnTimeStampTurn(time, step) {
  const value = new Date(time * step);
  let hour = value.getUTCHours().toString();
  let minute = value.getMinutes().toString();
  let second = value.getSeconds().toString();

  hour = hour === 0 ? "" : `${hour}:`;
  minute = minute.length === 1 ? `0${minute}:` : `${minute}:`;
  second = second.length === 1 ? `0${second}` : second;
  return `${hour}${minute}${second}`;
}

/**
 * 获取时间列表
 */
export function getTimeList() {
  let timeList = [];
  const weekList = [
    "星期日",
    "星期一",
    "星期二",
    "星期三",
    "星期四",
    "星期五",
    "星期六",
  ];
  const dayNumber = 60 * 60 * 24 * 1000;
  let currentTime = new Date().getTime();
  for (let i = 0; i < 14; i++) {
    let time = currentTime + i * dayNumber;
    timeList.push({
      createTime: time,
      week: i == 0 ? "今天" : weekList[new Date(time).getDay()],
      value: `${new Date(time).getMonth() + 1}月${new Date(time).getDate()}日`,
    });
  }
  return timeList;
}

/**
 * 获取星期
 */
export function weekDay(time) {
  let datelist = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
  return datelist[new Date(time).getDay()];
}

/**
 * @description 生成随机数
 * @param {Number} min 最小值
 * @param {Number} max 最大值
 * @return number
 */
export function randomNum(min, max) {
  let num = "";
  for (let index = 0; index < 6; index++) {
    num += Math.floor(Math.random() * (min - max) + max);
  }
  return num;
}
