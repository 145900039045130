export const messageState = {
  namespaced: true, //一定要开启命名空间。
  state: {
    operateBtn: false,
    typeId:1,
  },
  mutations: {
    setOperateShow(state, show) {
      state.operateBtn = show;
    },
    setMessageId(state,id){
      state.typeId = id;
    }
  },
};
