export const forecast = {
  namespaced: true,
  state: {
    forecastState: false, // 预测状态
    forecastStep: null, // 预测步骤
  },
  mutations: {
    setForecastState(state, data) {
      state.forecastState = data;
    },
    setForecastStep(state, step) {
      state.forecastStep = step;
    },
  },
};
