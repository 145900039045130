import Vue from "vue";
import VueRouter from "vue-router";

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const Layout = () => import("@/layout/index.vue");

// 子路由（二级路由）
const children = [
  // 首页
  {
    path: "/home/index",
    name: "home",
    component: () => import("@/views/home/index.vue"),
    meta: {
      title: "酷球体育",
      navShow: true,
    },
  },
  // 比赛
  {
    path: "/competition/index",
    name: "basketball",
    component: () => import("@/views/competition/index.vue"),
    meta: {
      title: "比赛实时比分",
    },
  },

  // 直播
  {
    path: "/directSeeding",
    name: "directSeeding",
    component: () => import("@/views/directSeeding/index.vue"),
    meta: {
      title: "比赛直播",
    },
  },
  // 直播详情
  {
    path: "/detail/:anchorId",
    name: "detail",
    component: () => import("@/views/directSeeding/detail.vue"),
    meta: {
      title: "直播详情",
    },
  },
  // 关注
  {
    path: "/follow",
    name: "follow",
    component: () => import("@/views/follow/index.vue"),
    meta: {
      title: "关注",
    },
  },
  // ai数据模型
  // {
  //   path: "/dataModel/index",
  //   name: "dataModel",
  //   component: () => import("@/views/dataModel/index.vue"),
  //   meta: {
  //     title: "AI数据模型",
  //   },
  // },
  // 行情
  // {
  //   path: "/quotation/index",
  //   name: "quotation",
  //   component: () => import("@/views/quotation/index.vue"),
  //   meta: {
  //     title: "行情",
  //   },
  //   children: [
  //     {
  //       path: "",
  //       name: "test",
  //       component: () =>
  //         import("@/views/quotation/components/quotation-index-content.vue"),
  //       meta: {
  //         title: "行情",
  //       },
  //     },
  //     {
  //       name: "quotationParams",
  //       path: "/params/:postId/:competition_id/:isActive/:logo",
  //       component: () =>
  //         import("@/views/quotation/components/quotation-details-content.vue"),
  //       meta: {
  //         title: "行情详情",
  //       },
  //     },
  //   ],
  // },
  // 福利
  {
    path: "/welfare/index",
    name: "welfare",
    component: () => import("@/views/welfare/index.vue"),
    meta: {
      title: "酷球体育",
      // openInNewWindow: true,
    },
  },

  // 热门推荐
  {
    path: "/recommendations/index",
    name: "recommendations",
    // component: () => import("@/views/recommendations/index.vue"),
    meta: {
      title: "热门推荐",
    },
  },
  // 资讯
  {
    path: "/information/index",
    name: "information",
    component: () => import("@/views/information/index.vue"),
    meta: {
      title: "资讯",
    },
  },
  // 资料库
  {
    path: "/dataBank",
    name: "dataBank",
    component: () => import("@/views/dataBank/index.vue"),
    meta: {
      title: "资料库",
    },
  },
  // 资料库联赛
  {
    path: "/leagueIndex/:ballType/:paramsData",
    name: "leagueIndex",
    component: () => import("@/views/dataBank/league/leagueIndex.vue"),
    meta: {
      title: "资料库-联赛",
    },
  },
  // 球员
  {
    path: "/player/:ballType/:playerId/:teamId/:seasonId",
    name: "player",
    component: () => import("@/views/dataBank/player/index.vue"),
    meta: {
      title: "球员信息",
    },
  },
  // 球队
  {
    path: "/team/:teamData",
    name: "team",
    component: () => import("@/views/dataBank/team/index.vue"),
    meta: {
      title: "球队信息",
    },
  },
  // 资讯详情页
  {
    path: "/details/:title/:articleid",
    name: "details",
    component: () => import("@/views/information/components/details.vue"),
    meta: {
      title: "资讯详情",
    },
  },
  // 欧洲杯
  // {
  //   path: "/EuroCup",
  //   name: "EuroCup",
  //   component: () => import("@/views/EuroCup/index.vue"),
  //   meta: {
  //     title: "欧洲杯",
  //   },
  // },

  // 比赛详情
  {
    path: "/components/broadcast/ballType-:ball/matchId-:matchId/sequence-:sequence/expert-:expert",
    name: "broadcast",
    component: () => import("@/views/recommendations/components/broadcast.vue"),
    meta: {
      title: "比赛详情",
      // openInNewWindow: true,
    },
  },
  // 个人中心
  {
    path: "/userInfo/index/:tabIndex",
    name: "userInfo",
    component: () => import("@/views/userInfo/index.vue"),
    meta: {
      title: "个人中心",
    },
  },
  // 主播中心
  {
    path: "/anchorInfo/:current",
    name: "anchorInfo",
    component: () => import("@/views/anchorInfo/index.vue"),
    meta: {
      title: "主播中心",
    },
  },
  // 支付火币
  // {
  //   path: "/aiModelPay/index",
  //   name: "aiModelPay",
  //   component: () => import("@/views/dataModel/components/aiModelPay.vue"),
  //   meta: {
  //     title: "购买模型",
  //   },
  // },
  // 个人中心充值
  {
    path: "/userInfoTopUp",
    name: "userInfoTopUp",
    component: () => import("@/views/userInfo/components/userInfoTopUp.vue"),
    meta: {
      // title: "充值",
    },
  },
  // 忘记密码
  {
    path: "/forgetPassword/index",
    name: "forgetPassword",
    component: () => import("@/views/forgetPassword/index.vue"),
    meta: {
      title: "忘记密码",
    },
  },

  // ai数据模型详情
  // {
  //   path: "/aiModelDetail/index",
  //   name: "aiModelDetail",
  //   component: () => import("@/views/dataModel/components/aiModelDetail.vue"),
  //   meta: {
  //     title: "AI预测模型",
  //   },
  // },
  // ai数据模型充值
  // {
  //   path: "/aiModelPay/index",
  //   name: "aiModelPay",
  //   component: () => import("@/views/dataModel/components/aiModelPay.vue"),
  //   meta: {
  //     title: "购买模型",
  //   },
  // },

  // ai过关
  {
    path: "/aiClearance/index",
    name: "aiClearance",
    component: () => import("@/views/aiClearance/index.vue"),
    meta: {
      title: "AI过关",
    },
  },
  {
    path: "/teamdata",
    name: "teamdata",
    component: () =>
      import("@/views/quotation/components/quotation-team-data.vue"),
    meta: {
      title: "球队数据",
    },
  },
  {
    path: "/expert",
    name: "expert",
    component: () => import("../views/expert/index.vue"),
    meta: {
      title: "专家",
    },
  },
  {
    path: "/expertHomePage/:expertId",
    name: "expertHomePage",
    component: () => import("../views/expert/components/expertHomePage.vue"),
    meta: {
      title: "专家个人主页",
    },
  },
  {
    path: "/expertPlan/:programme_id",
    name: "expertPlan",
    component: () => import("../views/expert/components/expertPlan.vue"),
    meta: {
      title: "方案详情",
    },
  },
  // 赛事分析/走势
  {
    path: "/analyzeTrends/currentType-:currentType/matchId-:matchId/ball-:ball",
    name: "analyzeTrends",
    component: () =>
      import("../views/recommendations/components/analyzeTrends/index.vue"),
    meta: {
      title: "赛事详情",
    },
  },
  // 增值电信
  {
    path: "/certificate",
    name: "certificate",
    component: () =>
      import("../layout/components/layoutFooter/certificate.vue"),
    meta: {},
  },
];

// 路由表
const routes = [
  {
    path: "/",
    redirect: "/home/index",
  },
  {
    path: "/layout",
    name: "layout",
    component: Layout,
    meta: {
      title: "layout",
    },
    children,
  },
  // 隐私政策/用户协议
  {
    path: "/ls_service",
    name: "ls_service",
    component: () => import("../views/h5/isService/index.vue"),
    meta: {
      title: "酷球-用户服务协议",
    },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../views/h5/privacy/index.vue"),
    meta: {
      title: "酷球-隐私政策",
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/register/index.vue"),
    meta: {
      title: "酷球-注册",
    },
  },
  {
    path: "/starDiamondFans",
    name: "starDiamondFans",
    component: () => import("../views/h5/starDiamondFans"),
    meta: {
      title: "酷球-星钻粉丝开通",
    },
  },
  // 落地页
  {
    path: "/landingPage",
    name: "landingPage",
    component: () => import("../views/landingPage/index.vue"),
    meta: {
      title: "酷球APP",
    },
  },
  // 直播协议
  {
    path: "/liveAgreement",
    name: "liveAgreement",
    component: () => import("../views/h5/liveAgreement/index.vue"),
    meta: {},
  },
  // h5 充值
  {
    path: "/webPageTopUp",
    name: "webPageTopUp",
    component: () => import("../views/webPageTopUp/index.vue"),
    meta: {
      // title: "h5充值",
    },
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () => import("../views/webPageTopUp/components/index.vue"),
    meta: {
      // title: "充值协议",
    },
  },
  // h5第三方信息
  {
    path: "/thirdInformation",
    name: "thirdInformation",
    component: () => import("../views/h5/thirdInformation/index.vue"),
    meta: {},
  },
  // h5个人信息
  {
    path: "/personalInformation",
    name: "personalInformation",
    component: () => import("../views/h5/personalInformation/index.vue"),
    meta: {},
  },
  // h5应用列表
  {
    path: "/applicationList",
    name: "applicationList",
    component: () => import("../views/h5/applicationList/index.vue"),
    meta: {},
  },
  // h5新增摘要
  {
    path: "/abstract",
    name: "abstract",
    component: () => import("../views/h5/abstract/index.vue"),
    meta: {},
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
  scrollBehavior: () => ({ x: 0, y: 0 }),
});

router.beforeEach((to, from, next) => {
  if (to.path == from.path) return;
  next();
  if (to.meta.openInNewWindow) {
    window.open("#" + to.fullPath);
    next(false); // 终止当前的导航
  } else {
    document.title = to.meta.title ? to.meta.title : "酷球体育";
    next(); // 继续正常导航
  }
});

// console.log(router.getRoutes());
function getRoutesList() {
  const arr = ["", "/layout"];
  const list = router.getRoutes();
  return list.filter((item) => {
    if (!arr.includes(item.path)) return true;
    return false;
  });
}
function getRoutesXML() {
  const url = "https://www.kuqiu.cn" + "/#"; // location.origin + "/#"
  const list = getRoutesList()
    .map((route) => `<url><loc>${url + route.path}</loc></url>`)
    .join("\r\n");
  return `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
    ${list}
  </urlset>`;
}
// console.log(getRoutesXML().toString());

export default router;
