<template>
  <div id="app">
    <!-- 路由出口 -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" :key="$route.fullPath" />
    </keep-alive>

    <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath" />
  </div>
</template>

<script>
export default {
  name: "App",
  created() {
    this.$store.commit("hainaStore/hainaInit");

    // 海纳sdk自定义埋点
    // this.$store.commit("hainaStore/getHainaClickData", {
    //   type: "H_WebPageLeave",
    // });
  },
};
</script>

<style lang="scss" scoped></style>
