export const barrage = {
  namespaced: true,
  state: {
    barrageSendList: [],
    pushDmData: {}, // 弹幕内容
    danmuNotice: false, // 是否开通贵族弹幕
    isNobleDanmuState: false, // 是否开启贵族弹幕
    updatePageShow: false, // 刷新页面
  },
  mutations: {
    setBarrageList(state, data) {
      state.barrageSendList = state.barrageSendList.concat([data]);
    },
    setPushDmData(state, data) {
      state.pushDmData = data;
    },
    setDanmuNotice(state, data) {
      state.danmuNotice = data;
    },
    setIsNobleDanmuState(state, data) {
      state.isNobleDanmuState = data;
    },
    setLivePageUpdata(state, data) {
      state.updatePageShow = data;
    },
  },
};
