export const base = {
  namespaced: true,
  state: {
    isType: null,
    broadcastRouteParams: {},
  },
  mutations: {
    setIsType(state, type) {
      state.isType = type;
    },
    setBroadcastRouteParams(state, arg) {
      state.broadcastRouteParams = { ...arg };
    },
    updataBroadcastRouteParams(state, arg) {
      const tmp = Object.assign(state.broadcastRouteParams, {
        ...arg,
      });
      state.broadcastRouteParams = { ...tmp };
    },
  },
};
