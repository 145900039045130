export const matchState = {
  namespaced: true, //一定要开始命名空间。
  state: {
    currentSession: 0, // 选中的串场数
    pageShow: 1, // 页面显示状态
    sessionList: [], // 比赛列表
    resultPage: false, // 分析页面显示
    resultData:{} // 分析的结果
  },
  mutations: {
    setCurrentSession(state, num) {
      state.currentSession = num;
    },
    setPageShow(state, show) {
      state.pageShow = show;
    },
    setSessionList(state, list) {
      state.sessionList = list;
    },
    setResultPage(state, show) {
      state.resultPage = show;
    },
    setResultData(state, data) {
      state.resultData = data;
    },
  },
};
