import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { matchState } from "./modules/match"; // ai串关
import { messageState } from "./modules/message";
import { base } from "./modules/base";
import { hainaStore } from "./modules/haina"; // 海纳
import { live } from "./modules/live"; // 直播间
import { barrage } from "./modules/barrage"; // 直播间弹幕
import { forecast } from "./modules/forecast"; // 欧洲杯预测
import Vue from "vue";
import { update } from "lodash";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    h5TopUpMask: false, // h5确定支付弹框
    preResultMask: false,
    seasonId: 0,
    isActive: 1,
    loseTokenMaskShow: false,
    // 1为登录弹框
    loginState: 0,
    // 当前页点击高亮
    current: 0,
    // ai模型组件
    modelCurrent: 0,
    competitionCurrent: 0,
    // 比赛赛事筛选
    eventselection: [],
    // 比赛赛事筛选-收藏
    followeventselection: [],
    // 比赛赛事筛选-篮球收藏
    bskfolloweventselection: [],
    // 比赛赛选后数据
    eventselectionList: [],
    // 比赛赛事筛选-篮球
    bskEventselection: [],
    // 指数-让球
    asiaselection: [],
    // 让球赛选数据
    asiaselectionList: [],
    // 指数-进球数
    bsselection: [],
    // 进球数赛选数据
    bsselectionList: [],
    // 比赛筛选方法调用
    showRelatedData: false,
    // 资讯
    informationCurrent: 0,
    // ai过关
    aiPassCurrent: 0,
    // 个人中心
    userInforCurrent: 0,
    // 专家方案id
    programmeId: null,
    //  专家推荐比赛倒计时
    time: 0,
    //  行情侧边导航
    iscolor: null,
    // token
    token: null,
    userInfo: {},
    //资讯详情标题
    titleContent: "",
    //资讯详情文章id
    articleid: 0,
    // 注册邀请码
    inviteCode: null,
    // 订单号
    orderNo: null,
    // pc订单号
    webOrderNo: null,
    // 赛季id
    // eslint-disable-next-line no-dupe-keys
    seasonId: null,
    // 贵族弹框
    noblesFrameMask: false,
    // 星钻粉丝弹框
    diamondPopup: false,
    // 直播间信息
    liveRoomData: {},
    // 直播开启数据
    createRoom: {},
    // 资料库 球队 赛事id
    dataBankCompetitionId: null,
  },
  // 响应组件中的事件
  actions: {},
  mutations: {
    setTopUpMask(state, data) {
      state.h5TopUpMask = data;
    },
    // 资料库球队赛事id
    setdataBankCompetitionId(state, dataBankCompetitionId) {
      state.dataBankCompetitionId = dataBankCompetitionId;
    },
    // 直播开启数据
    setcreateRoom(state, createRoom) {
      state.createRoom = createRoom;
    },
    // 预言结果弹框
    setPreResultMask(state, type) {
      state.preResultMask = type;
    },
    // 热门推荐id
    setseasonId(state, num) {
      state.seasonId = num;
    },
    // 行情侧边导航type传参
    setIsActive(state, num) {
      state.isActive = num;
    },
    // layout导航栏
    setCurrent(state, num) {
      state.current = num;
    },
    // ai模型
    setModelCurrent(state, modelCurrent) {
      state.modelCurrent = modelCurrent;
    },
    // 资讯
    setInforCurrent(state, infoCurrent) {
      state.informationCurrent = infoCurrent;
    },
    // 比赛
    setCompCurrent(state, competCurrent) {
      state.competitionCurrent = competCurrent;
    },

    // 比赛赛事赛选（比分）
    setEventSelection(state, eventSelection) {
      state.eventselection = eventSelection;
    },
    // 比赛赛事赛选（收藏）
    setFollowEventSelection(state, followEventSelection) {
      state.followeventselection = followEventSelection;
    },
    // 比赛赛事赛选（篮球收藏）
    setBskFollowEventSelection(state, bskfollowEventSelection) {
      state.bskfolloweventselection = bskfollowEventSelection;
    },
    // 比赛赛事赛选篮球（比分）
    setBskEventSelection(state, bskEventSelection) {
      state.bskEventselection = bskEventSelection;
    },
    // 比赛赛选后数据
    setEventSelectionList(state, eventSelectionList) {
      state.eventselectionList = eventSelectionList;
    },
    // 比赛指数-让球
    setAsiaselection(state, asiaSelection) {
      state.asiaselection = asiaSelection;
    },
    // 让球赛选数据
    setAsiaSelectionList(state, asiaSelectionList) {
      state.asiaselectionList = asiaSelectionList;
    },
    // 比赛指数-进球数
    setBsselection(state, bsSelection) {
      state.bsselection = bsSelection;
    },
    // 进球数赛选数据
    setBsSelectionList(state, bsSelectiongList) {
      state.bsselectionList = bsSelectiongList;
    },
    setShowRelatedData(state, payload) {
      state.showRelatedData = payload;
    },
    // 个人中心
    setUserCurrent(state, userCurrent) {
      state.userInforCurrent = userCurrent;
    },
    // 专家方案id
    setprogrammeId(state, id) {
      state.programmeId = id;
    },
    //  专家推荐比赛倒计时
    setTime(state, programmeTime) {
      state.time = programmeTime;
    },

    //  行情侧边导航
    setIscolor(state, textColor) {
      state.iscolor = textColor;
    },
    // ai过关
    setPassCurrent(state, passCurrent) {
      state.aiPassCurrent = passCurrent;
    },
    // token失效强制，用户操作需要强制登录
    setLoseTokenMaskShow(state, show) {
      state.loseTokenMaskShow = show;
    },
    // 操作token
    setToken(state, token) {
      state.token = token;
    },
    // userInfo
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    // 资讯详情标题
    setTitleContent(state, title) {
      state.titleContent = title;
    },
    // 资讯详情文章id
    setArticleid(state, num) {
      state.articleid = num;
    },
    // 邀请码
    setInviteCode(state, code) {
      state.inviteCode = code;
    },
    // 订单号
    setOrderNo(state, orderNum) {
      state.orderNo = orderNum;
    },
    // pc端订单号
    setWebOrderNo(state, orderNo) {
      state.webOrderNo = orderNo;
    },
    // 赛季id
    setSeasonId(state, season_id) {
      state.seasonId = season_id;
    },
    setNoblesFrame(state, value) {
      state.noblesFrameMask = value;
    },
    // 星钻粉丝弹框
    setDiamondPopup(state, diamondPopupShow) {
      state.diamondPopup = diamondPopupShow;
    },
    // 直播间信息
    setLiveRoomData(state, getliveRoomData) {
      state.liveRoomData = getliveRoomData;
    },
    setTopUpMask(state, data) {
      state.h5TopUpMask = data;
    },
  },
  // 模块化
  modules: {
    matchState,
    messageState,
    base,
    hainaStore,
    live,
    barrage,
    forecast,
  },
  // 持久化
  plugins: [
    createPersistedState({
      reducer(val) {
        const data = { ...val };
        delete data.matchState;
        delete data.messageState;
        delete data.loseTokenMaskShow;
        delete data.aiPassCurrent;
        delete data.current;
        return {
          ...data,
          forecast: { forecastState: data.forecast.forecastState },
        };
      },
    }),
    // createPersistedState({
    //   key: "current",
    //   storage: window.sessionStorage,
    //   reducer(val) {
    //     const dataVal = { ...val };
    //     for (const key in val) {
    //       if (key != "current") delete dataVal[key];
    //     }
    //     return dataVal;
    //   },
    // }),
  ],
});
export default store;
