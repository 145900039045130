<template>
  <div class="container" :class="[hasContent ? '' : 'empty']">
    <slot v-if="hasContent"></slot>
    <div v-else class="empty-state">
      <!-- 暂无数据 -->
      <img
        v-if="meteDataEmpty && !systemErrorFlag && !networkError"
        :src="require('@/assets/img/empty_state/nodata.gif')"
        :alt="'数据都去火星啦(ノへ￣、)'"
        class="empty-state-image"
        ref="img"
      />
      <h3
        v-if="meteDataEmpty && !systemErrorFlag && !networkError"
        class="empty-state-text"
      >
        数据都去火星啦(ノへ￣、)
      </h3>
      <!-- 异常界面 -->
      <img
        v-if="systemErrorFlag && !networkError"
        :src="require('@/assets/img/empty_state/network_disconnection.png')"
        :alt="'界面异常，请稍后再试'"
        class="empty-state-image"
        ref="img"
      />
      <h3 v-if="systemErrorFlag && !networkError" class="empty-state-text">
        系统异常，请稍后再试
      </h3>
      <!-- 网络错误 -->
      <img
        v-show="networkError"
        :src="networkErrorImgC"
        :alt="'未连接到网络'"
        class="empty-state-image"
        ref="img"
      />
      <h3 v-if="networkError" class="empty-state-text">未连接到网络</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "emptyS",
  props: {
    systemError: {
      type: Boolean,
      default: false,
    },
    meteData: {
      type: [String, Array, Object, Number, undefined, null],
      default: () => [],
    },
  },
  watch: {
    meteData(newVal, oldVal) {
      this.meteDataEmpty = this.isEmpty(newVal);
    },
    systemError(newVal, oldVal) {
      this.systemErrorFlag = this.systemError;
    },
  },
  data() {
    return {
      hasContent: false,
      networkError: false,
      meteDataEmpty: false,
      systemErrorFlag: false,
      networkErrorImgC: require("../assets/img/empty_state/abnormal.png"),
    };
  },
  mounted() {
    this.hasContent = Boolean(this.$slots.default);
    this.checkNetworkState();
    window.addEventListener("online", this.handleOnline);
    window.addEventListener("offline", this.handleOffline);
  },
  updated() {
    this.hasContent = Boolean(this.$slots.default);
    this.checkNetworkState();
    window.addEventListener("online", this.handleOnline);
    window.addEventListener("offline", this.handleOffline);
  },
  beforeDestroy() {
    window.removeEventListener("online", this.handleOnline);
    window.removeEventListener("offline", this.handleOffline);
  },
  methods: {
    //检查网络状态
    checkNetworkState() {
      this.networkError = !window.navigator.onLine;
      this.meteDataEmpty = this.isEmpty(this.meteData);
      this.systemErrorFlag = this.systemError;
    },
    handleOnline() {
      this.networkError = false;
    },
    handleOffline() {
      this.networkError = true;
    },
    //判断是否为空
    isEmpty(val) {
      let result = false;
      if (val === null || val === undefined || val === "") {
        result = true;
      } else {
        if (typeof val === "string" && val.trim() === "") {
          result = true;
        }
        if (val instanceof Array && val.length === 0) {
          result = true;
        }
        if (JSON.stringify(val) === "{}") {
          result = true;
        }
      }
      return result;
    },
  },
};
</script>

<style>
.container {
  display: flex;
  flex-wrap: wrap;
}

.container.empty {
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.empty-state {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-top: 32px;
  padding: 32px; */
  text-align: center;
  font-size: 16px;
  color: #666;
  position: relative;
  background: #fff;
}

.empty-state-image {
  max-width: 33%;
  padding: 113px 0 133px 0;
  height: auto;
}

.empty-state-text {
  position: absolute;
  bottom: 78px;
  margin-top: 16px;
}
</style>
