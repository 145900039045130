export const live = {
  namespaced: true,
  state: {
    liveRoomId: null, // 直播间id
    anchorGrage: [], // 主播等级经验
    appointList: [], // 预约列表
    myFollowList: [], // 比赛列表
    liveOpenState: false, // 开播状态
    liveVideoState: 1, // 视频播放状态 0-未开播 1-开播没画面 2-开播且有画面
    predictedResult: {}, // 直播间预言结果
    predictedId: null, // 预言id
    prohibitionId: null, // 禁言id
    prohibitionInfo: {}, // 禁言信息
    liveMutedState: null, // 刚进直播间的禁言状态
    chatMutedState: false, // 聊天室被禁言后的状态
    chooseTextColor: {
      id: 0,
      color: "#2C3E51",
    },
    noblesIndex: 0,
  },
  mutations: {
    // 直播间id
    setLiveId(state, id) {
      state.liveRoomId = id;
    },
    getAppoints(state, list) {
      state.appointList = list;
    },
    getFollowData(state, data) {
      state.myFollowList = data;
    },
    setLiveState(state, data) {
      state.liveOpenState = data;
    },
    setVideoState(state, data) {
      state.liveVideoState = data;
    },
    setLivePreResult(state, obj) {
      state.predictedResult = obj;
    },
    setLivePreId(state, id) {
      state.predictedId = id;
    },
    setProhibitionData(state, data) {
      state.prohibitionInfo = data;
    },
    setMutedLiveState(state, data) {
      state.liveMutedState = data;
    },
    setChatMutedState(state, bool) {
      state.chatMutedState = bool;
    },
    setLiveAnchorGrage(state, data) {
      state.anchorGrage = data;
    },
    setProhibitionId(state, id) {
      state.prohibitionId = id;
    },
    setTextColor(state, color) {
      state.chooseTextColor = color;
    },
    setNoblesIndex(state, index) {
      state.noblesIndex = index;
    },
  },
};
