import { apiPost, apiGet } from "./request";

export const footAPI = {
  // 首页
  //热门推荐比赛
  recommend(data) {
    return apiPost("/api/Index/recommend", data);
  },
  // 进行中的比赛数量统计
  startMatchCount(data) {
    return apiPost("/api/index/startMatchCount", data);
  },
  // 最近比赛
  getLastMatch(params) {
    return apiGet("/go/home/getLastMatch", params);
  },
  // 预约比赛
  matchBook(params) {
    return apiPost("/go/user/matchBook", params);
  },
  // 取消预约比赛
  unMatchBook(params) {
    return apiPost("/go/user/cancelMatchBook", params);
  },
  // 推荐主播
  recommandCast(data) {
    return apiGet("/go/home/recommandCast", data);
  },

  // 关注用户
  followUser(data) {
    return apiGet("/api/community/follow_user", data);
  },
  // 取关用户
  unfollowUser(data) {
    return apiGet("/api/community/unfollow_user", data);
  },
  // 进行中的直播
  casting(data) {
    return apiGet("/go/home/casting", data);
  },

  // 热门赛事
  categoryList(data) {
    return apiPost("/api/Index/categoryList", data);
  },

  // 比赛实时比分接口
  process(params) {
    return apiGet("/api/Competition/process", params);
  },
  // 比赛比分
  course(params) {
    return apiGet("/api/competition/course", params);
  },
  // 比赛赛果
  amidithion(params) {
    return apiGet("/api/competition/amidithion", params);
  },
  // 赛事筛选
  hotCompetitionList(params) {
    return apiPost("/api/competition/hotCompetitionList", params);
  },
  // 比赛指数
  matchOdds(params) {
    return apiGet("/api/match/matchOdds", params);
  },
  // 比赛球队历史比赛结果
  getTeamHistory(params) {
    return apiGet("/api/match/getTeamHistory", params);
  },
  // 比赛角球指数
  getPcCornerInfo(params) {
    return apiGet("/api/match/getPcCornerInfo", params);
  },
  // 比赛赛事基本信息
  matchDetail(data) {
    return apiGet("/api/Match/detail", data);
  },

  // 收藏比赛
  followMatch(params) {
    return apiGet("/api/user/follow_match_list", params);
  },

  // 关注
  follow_match(params) {
    return apiPost("/api/user/follow_match", params);
  },
  // 取消关注
  unfollow_match(params) {
    return apiPost("/api/user/unfollow_match", params);
  },
  // 积分榜单-赛事
  defaultSeasonTable(params) {
    return apiPost("/api/competition/defaultSeasonTable", params);
  },
  // 积分榜单-数据
  getSeasonTable(params) {
    return apiPost("/api/competition/getSeasonTable", params);
  },
  // 积分榜单-队员数据
  getSeasonPlayer(params) {
    return apiPost("/api/competition/getSeasonPlayer", params);
  },
  // 球队详情页
  getteamData(params) {
    return apiPost("/api/competition/teamData", params);
  },
  // 获取球队比赛列表(未开始、正在进行 、已结束)
  teamMatchList(params) {
    return apiPost("/api/competition/teamMatchList", params);
  },
  //热门推荐 - 比赛详情AI分析
  ai(params) {
    return apiGet("/api/match/ai", params);
  },
  //热门推荐 - 球队数据
  TeamData(params) {
    return apiGet("/api/Match/TeamData", params);
  },
  //热门推荐 - 球队阵容
  lineup(params) {
    return apiGet("/api/match/lineup", params);
  },
  //热门推荐 - 球队指数
  getIndex(params) {
    return apiGet("/api/match/getIndex", params);
  },
  //热门推荐 - 赛场数据
  courtData(params) {
    return apiGet("/api/match/courtData", params);
  },

  // 专家主页
  detail(params) {
    return apiGet("/api/expert/detail", params);
  },
  // 专家 详情页
  programmeDetail(params) {
    return apiGet("/api/expert/programme_detail", params);
  },
  // 专家主页 足球篮球推荐
  detailRecommend(params) {
    return apiGet("/api/expert/detail_recommend", params);
  },
  // 专家关注
  follow(params) {
    return apiPost("/api/expert/follow", params);
  },
  // 专家取消
  unfollow(params) {
    return apiPost("/api/expert/unfollow", params);
  },
  // 用户购买方案
  buy(params) {
    return apiPost("/api/order/buy", params);
  },

  //资讯栏目列表
  articleList(params) {
    return apiPost("/api/article/articleList", params);
  },
  //资讯推荐栏目列表
  catList(params) {
    return apiPost("/api/article/catList", params);
  },
  // 资讯一级评论
  commentlist(params) {
    return apiGet("/api/community/commentlist", params);
  },
  // 资讯二级评论
  reply_commentlist(params) {
    return apiGet("/api/community/reply_commentlist", params);
  },

  // 首页获得推荐专家
  recommendlist(params) {
    return apiGet("/api/expert/recommendlist", params);
  },

  // 用户平台等级
  userLevelConfig(params) {
    return apiGet("/api/user/user_level_config", params);
  },
  // 粉丝等级
  fansList(params) {
    return apiGet("/go/home/fansList", params);
  },

  // 直播室
  //开启直播
  createRoom(params) {
    return apiPost("/go/live/createRoom", params);
  },
  // 直播间信息
  liveInfo(data) {
    return apiGet("/go/home/liveInfo", data);
  },

  //下播
  downRoom(params) {
    return apiPost("/go/live/downRoom", params);
  },
  //直播结束数据
  oneLiveStat(data) {
    return apiGet("/go/live/oneLiveStat", data);
  },
  // 直播提醒
  remindlive(params) {
    return apiPost("/go/live/remindlive", params);
  },
  // get方式获取房间信息 post方式提交更新并返回房间信息
  liveRoomManageChange(data) {
    return apiPost("/api/user/live_room_manage", data);
  },
  liveRoomManage(data) {
    return apiGet("/api/user/live_room_manage", data);
  },
  // 直播比赛选择
  predictionMatch(data) {
    return apiGet("/api/user/prediction_match", data);
  },
  // 周贡榜
  weekCon(params) {
    return apiGet("/go/home/weekCon", params);
  },
  // 总贡榜
  totalCon(params) {
    return apiGet("/go/home/totalCon", params);
  },
  // 贵宾榜
  honorRank(params) {
    return apiGet("/go/home/honorRank", params);
  },
  // 粉丝榜
  fansRank(params) {
    return apiGet("/go/home/fansRank", params);
  },
  // 星钻排名
  diamondRank(data) {
    return apiGet("/go/home/diamondRank", data);
  },
  // 星钻粉丝价格列表
  fansPriceList(data) {
    return apiGet("/go/home/fansPriceList", data);
  },
  //开通星钻粉丝
  buyDiamond(params) {
    return apiPost("/go/live/buyDiamond", params);
  },

  // 这个比赛是否显示评分
  isOpen(data) {
    return apiGet("/go/score1/isOpen", data);
  },

  // 选手评分-热门对局
  hot(data) {
    return apiGet("/go/score1/hot", data);
  },
  // 选手评分-本周热门
  home(data) {
    return apiGet("/go/score1/home", data);
  },

  // 选手评分-全部赛程
  byCompetition(data) {
    return apiGet("/go/score/byCompetition", data);
  },
  // 选手评分-全部赛程
  matchHome(data) {
    return apiGet("/go/score/matchHome", data);
  },
  // 神评
  godScore(data) {
    return apiGet("/go/score/godScore", data);
  },
  // 全部评论
  allScore(data) {
    return apiGet("/go/score/allScore", data);
  },
  // 点赞
  goodJob(params) {
    return apiPost("/go/score/goodJob", params);
  },
  // 回复/评论
  putScore(params) {
    return apiPost("/go/score/putScore", params);
  },
  // 修改评分
  Score(params) {
    return apiPost("/go/score/Score", params);
  },
  // 粉丝牌
  myFansCard(data) {
    return apiGet("/api/user/my_fans_card", data);
  },
  //是否有未读消息
  isRead(data) {
    return apiGet("/go/score/isRead", data);
  },
  // 收到的回复
  replyList(data) {
    return apiGet("/go/score/replyList", data);
  },

  // 资料库
  // 首页其他赛事
  infobaseHome(data) {
    return apiGet("/api/infobase/index", data);
  },
  // 首页排名赛事
  newSeasonTable(params) {
    return apiPost("/api/competition/newSeasonTable", params);
  },
  // 首页排名赛事-积分
  newGetSeasonTable(params) {
    return apiPost("/api/competition/newGetSeasonTable", params);
  },

  // 赛季选择
  getSeasonList(data) {
    return apiGet("/api/trend/getSeasonList", data);
  },
  // 足球联赛——赛程赛果
  schedule(data) {
    return apiGet("/api/infobase/schedule", data);
  },
  // 联赛-数据统计-让球统计（上方）
  letballAnalyse(data) {
    return apiGet("/api/infobase/letball_analyse", data);
  },
  // 联赛-数据统计-排行
  letballRank(data) {
    return apiGet("/api/infobase/letball_rank", data);
  },
  // 联赛-大小球—上方统计内容
  bsAnalyse(data) {
    return apiGet("/api/infobase/bs_analyse", data);
  },
  // 联赛-大小球—排行榜
  bsRank(data) {
    return apiGet("/api/infobase/bs_rank", data);
  },
  // 联赛-比分—上方统计内容
  scoreAnalyse(data) {
    return apiGet("/api/infobase/score_analyse", data);
  },
  // 联赛-比分
  scoreRank(data) {
    return apiGet("/api/infobase/score_rank", data);
  },
  // 联赛-进球—上方统计内容
  goalAnalyse(data) {
    return apiGet("/api/infobase/goal_analyse", data);
  },
  // 联赛-进球—统计内容
  goalRank(data) {
    return apiGet("/api/infobase/goal_rank", data);
  },
  // 联赛-积分排名足球
  ranking(data) {
    return apiGet("/api/infobase/football_league_point_ranking", data);
  },
  // 射手-类型
  rankingTypeList(params) {
    return apiPost("/api/competition/rankingTypeList", params);
  },
  // 射手-排名
  seasonTeamsInfo(params) {
    return apiPost("/api/competition/seasonTeamsInfo", params);
  },
  // 射手-球员-篮球
  getBasketballCompetitionBestPlayer(data) {
    return apiGet("/go/basketball/getBasketballCompetitionBestPlayer", data);
  },

  // 射手-球员-足球
  getFootballCompetitionBestPlayer(data) {
    return apiGet("/go/football/getFootballCompetitionBestPlayer", data);
  },

  // 篮球-联赛-赛程阶段分组数据
  kindList(params) {
    return apiPost("/api/basketballdata/kindList", params);
  },
  // 篮球-联赛-月份阶段分组数据
  seasonMonthList(params) {
    return apiPost("/api/basketballdata/seasonMonthList", params);
  },
  // 篮球-联赛-赛程赛果数据
  seasonMatchList(params) {
    return apiPost("/api/basketballdata/seasonMatchList", params);
  },
  // 篮球-联赛-积分排名
  seasonTableDetail(params) {
    return apiPost("/api/basketballdata/seasonTableDetail", params);
  },

  // 球队
  // 球队信息
  teamInfo(params) {
    return apiPost("/api/teamdata/teamInfo", params);
  },
  // 足球球队概览
  getFootballTeamOverView(data) {
    return apiGet("/go/football/getFootballTeamOverView", data);
  },
  // 关键词数据
  playerCruxInfo(data) {
    return apiGet("/api/infobase/player_crux_info", data);
  },
  // 足球赛事的赛季
  getSeasonYears(data) {
    return apiGet("/api/infobase/getSeasonYears", data);
  },
  // 足球赛事的赛事
  resultCompetitionList(data) {
    return apiGet("/api/infobase/result_competition_list", data);
  },
  // 足球赛事的数据列表
  footballResultList(data) {
    return apiGet("/api/infobase/football_result_list", data);
  },
  // 足球-数据统计-基础数据
  footballDataStatBaseData(data) {
    return apiGet("/api/infobase/football_data_stat_base_data", data);
  },
  // 足球-数据统计-赛事统计
  footballDataStatRanking(data) {
    return apiGet("/api/infobase/football_data_sate_ranking", data);
  },
  // 足球-数据统计-数据分析
  footballDataStatList(data) {
    return apiGet("/api/infobase/football_data_stat_list", data);
  },

  // 教练数据
  getCoachHistory(data) {
    return apiGet("/api/infobase/getCoachHistory", data);
  },
  // 球队近期参加赛事
  getFootballTeamLastMatch(data) {
    return apiGet("/go/football/getFootballTeamLastMatch", data);
  },

  // 篮球-球队-赛季列表
  seasonList(params) {
    return apiPost("/api/basketballdata/getSeasonList", params);
  },
  // 篮球-球队-参加的赛事
  competitionList(params) {
    return apiPost("/api/basketballdata/competitionList", params);
  },
  // 篮球-球队-赛事列表
  competitionMatchList(params) {
    return apiPost("/api/basketballdata/competitionMatchList", params);
  },
  // 篮球-球队-球队数据
  basketballTeamSeasontOdds(params) {
    return apiPost("/api/teamdata/basketballTeamSeasontOdds", params);
  },
  // 篮球-球队-球员数据-赛季
  defaultCompetitionSeason(params) {
    return apiPost("/api/basketballdata/defaultCompetitionSeason", params);
  },
  // 篮球-球队-球员数据
  teamPlayer(params) {
    return apiPost("/api/basketballdata/teamPlayer", params);
  },

  // 足球球员基本信息-转会
  getFootballPlayer(data) {
    return apiGet("/go/football/getFootballPlayer", data);
  },
  // 足球球员比赛信息
  footballPlayerMatchData(data) {
    return apiGet("/api/infobase/football_player_match_data", data);
  },
  // 足球球员基本信息
  footballPlayerBasic(data) {
    return apiGet("/api/infobase/football_player_basic", data);
  },
  // 篮球球员基本信息
  getBasketballPlayer(data) {
    return apiGet("/go/basketball/getBasketballPlayer", data);
  },
  // 篮球球员比赛数据
  playerMatch(params) {
    return apiPost("/api/basketballdata/playerMatch", params);
  },
  // 篮球足球热门球员
  hotPlayer(params) {
    return apiPost("/api/basketballdata/hotPlayer", params);
  },
  // 赛事-分组积分
  seasonTableIntegral(params) {
    return apiPost("/api/competition/seasonTableDetail", params);
  },

  // 欧洲杯赛事
  matchList(params) {
    return apiPost("/api/topics/matchList", params);
  },
  // 所选比赛主客队的战绩指数
  record(params) {
    return apiPost("/api/topics/record", params);
  },
  // 赛程-赛事阶段
  stageList(params) {
    return apiPost("api/topics/stageList", params);
  },
  // 赛程-时间赛事
  matchSchedule(params) {
    return apiPost("/api/topics/matchSchedule", params);
  },

  // 个人数据
  my(data) {
    return apiGet("/api/user/my", data);
  },
};

// 把所有的API对象汇总到一个入口文件中
export default {
  foot: footAPI,
};
