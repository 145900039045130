import hina from "@/hina/hina.esm.min.js";

export const hainaStore = {
  namespaced: true,
  state: {
    haina: hina,
  },
  mutations: {
    // 初始化
    hainaInit(state) {
      // 接入sdk
      state.haina.init({
        serverUrl:
          "https://higateway.haishuu.com/hn?project=ku_qiu_web&token=jvBMVLZ3", // 配置采集上报地址URL（在线申请），查看如下【[采集上报地址获取] 链接
        // 设置 true 后会在网页控制台打 logger，会显示发送的数据,设置 false 表示不显示。
        showLog: false,
        sendType: 'ajax',
        //单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 H_pageview 事件
        isSinglePage: true,
        autoTrackConfig: {
          //是否开启自动点击采集, true表示开启，自动采集 H_WebClick 事件
          clickAutoTrack: true,
          //是否开启页面停留采集, true表示开启，自动采集 H_WebStay 事件
          stayAutoTrack: true,
        },
      });
      //用于采集 H_pageview 事件
      state.haina.quick("autoTrack");
    },
    // 事件
    getHainaClickData(state, data) {
      state.haina.track(data?.type, data?.config);
    },
    // 登录成功
    hainaLoginApi(state, userId) {
      state.haina.setUserUId(userId);
    },
  },
};
